<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Staff Affairs</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Staff-Affairs' }">Staff Affairs</router-link>
          </li>
          <li class="breadcrumb-item active">Staff</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link v-can="'add_staff'" :to="{ name: 'Staff-Affairs-Staff-Create' }" class="btn btn-primary">Add
              New</router-link>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <form @submit.prevent="submitSearchForm()" class="form-inline">
                  <!-- Staff Code -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Code</label>
                    <input type="text" class="form-control" v-model="search_fields.code" name="free_search_input"
                      placeholder="Staff Code">
                  </div>
                  <!-- Staff name -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Name</label>
                    <input type="text" class="form-control" v-model="search_fields.name" name="free_search_input"
                      placeholder="Staff Name">
                  </div>
                  <!-- Roles -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Role</label>
                    <select class="form-control" v-model="search_fields.role">
                      <option value="" selected>All</option>
                      <option v-for="role in lists.roles" :key="role.id" :value="role.id">{{ role.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-2 mr-2">
                    <button name="search" type="submit" class="btn btn-primary" value="Filter" onclick="this.blur();">
                      Filter
                    </button>
                  </div>

                </form>
              </div>
            </div>
            <div class="table-responsive">
              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="staff" alternating buttons-pagination>
                <template #item-roles="{ roles }">
                  <div>
                    <span v-for="(role, i) in roles" :key="i">
                      {{ role }}
                    </span>
                  </div>
                </template>
                <template #item-actions="{ id }">
                  <div>
                    <router-link :to="{
                      name: 'Staff-Affairs-Staff-Single',
                      params: { id: id },
                    }" title="View" class="mr-4"><i class="fa fa-eye color-muted"></i>
                    </router-link>
                    <router-link v-can="'edit_staff'" :to="{
                      name: 'Staff-Affairs-Staff-Edit',
                      params: { id: id },
                    }" title="Edit" class="mr-4"><i class="fa fa-pencil color-muted"></i>
                    </router-link>
                    <a v-can="'delete_staff'" @click.prevent="deleteAccount(id)" href="javascript:void()"
                      title="Delete"><i class="fa fa-close color-danger"></i></a>
                  </div>
                </template>
              </EasyDataTable>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStaff from "@/composables/staff";
import { inject, onBeforeMount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
export default {
  components: {
    EasyDataTable,
  },
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const loading = ref(true);

    const search_fields = reactive({
      code: "",
      name: "",
      role: '',
    });

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_staff")) {
        router.push({
          name: "401",
        });
      } else {
        getStaff().then(() => {
          loading.value = false;
        });
      }
    });

    const { staff, lists, getStaff, deleteStaff } =
      useStaff();

    const headers = ref([
      { text: "ID", value: "id" },
      { text: "Code", value: "code", sortable: true },
      { text: "Name", value: "fullname", sortable: true },
      { text: "Phone", value: "phone", sortable: true },
      { text: "Email", value: "email", sortable: true },
      { text: "Roles", value: "roles", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    const deleteAccount = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteStaff(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getStaff();
            });
          }
        });
    };

    const submitSearchForm = async () => {
      loading.value = true;
      await getStaff(search_fields).then(() => {
        loading.value = false;
      });
    };

    return {
      loading,
      headers,
      staff,
      lists,
      search_fields,
      deleteAccount,
      submitSearchForm,
    };
  },
};
</script>

<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
